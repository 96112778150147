export const jobsObjects = [
    {
      img: `${process.env.PUBLIC_URL}/cameras_images/handymanLogo.png`,
      title: "הנדימן",
      subTitle: "הנדימן - עבודות שיפוץ כל",
      description:
       "מתן שירות ע״י בעלי מקצוע מנוסים במגוון תחומים כגון: אינסטלציה, התקנות ופירוקים, צבע ושפכטל, עבודות נגרות, ניקוי, חיטוי, תיקון והתקנת מזגנים וכו׳",
      jobs:[
          {
              title:"גופי תאורה ונברשות",
              description:["ספוטים","פסי לד","פסי צבירה","נברשות","תאורת גן","פרוג'קטור סולארי"],
              jobInfo: "התקנה ותיקון של גופי תאורה ונברשות מכל הסוגים.",
              gallery:[
                  `${process.env.PUBLIC_URL}/jobs_gallary/light_1.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/light_2.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/light_3.png`,
              ],
              icon:`${process.env.PUBLIC_URL}/job_icons/light_white.png`
          },
          {
              title:"עבודות אינסטלציה",
              description:["התקנת ברזים","התקנת כיורים","תיקוני צנרת"],
              jobInfo: "התקנת ברזים, כיורים ותיקוני צנרת.",
              gallery:[
                  `${process.env.PUBLIC_URL}/jobs_gallary/plumber_1.jpeg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/plumber_2.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/plumber_3.jpg`,
              ],
              icon:`${process.env.PUBLIC_URL}/job_icons/plumber_white.png`
          },
          {
              title:"התקנות ופרוקים",
              description:["מזגנים","תמונות","מטבחים","מדפים","טלוויזיות","ארונות","מנעולים וצלינדרים"],
              jobInfo: "התקנה ופרוק של מזגנים, תמונות, מטבחים, מדפים, טלוויזיות, ארונות ומנעולים.",
              gallery:[
                  `${process.env.PUBLIC_URL}/jobs_gallary/installations_1.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/installations_2.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/installations_3.jpg`,
              ],
              icon:`${process.env.PUBLIC_URL}/job_icons/build_white.png`
          },
          {
              title:"עבודות צבע/שפכטל",
              description:["צבע כללי","תיקוני טיח/שפכטל","צביעה נגד עובש"],
              jobInfo: "עבודות צבע ושפכטל כולל תיקונים וצביעה נגד עובש.",
              gallery:[
                  `${process.env.PUBLIC_URL}/jobs_gallary/paint_1.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/paint_2.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/paint_3.png`,
              ],
              icon:`${process.env.PUBLIC_URL}/job_icons/paint_white.png`
          },
          {
              title:"התקנת מזגנים/מאווררים",
              description:["התקנת מזגנים","ניקוי מזגנים","תיקון תקלות","התקנת מאווררים"],
              jobInfo: "התקנה, ניקוי ותיקון של מזגנים ומאווררים.",
              gallery:[
                  `${process.env.PUBLIC_URL}/jobs_gallary/ac_1.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/ac_2.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/ac_3.jpg`,
              ],
              icon:`${process.env.PUBLIC_URL}/job_icons/ac_install_white.png`
          },
          {
              title:"חיפוי קיר דקורטיבי",
              description:["טפט","חיפוי דקורטיבי","חיפוי סרגל לקירות פולימר"],
              jobInfo: "התקנת חיפויי קיר דקורטיביים וטפטים.",
              gallery:[
                  `${process.env.PUBLIC_URL}/jobs_gallary/wallpaper_install_1.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/wallpaper_install_2.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/wallpaper_install_3.jpg`,
              ],
              icon:`${process.env.PUBLIC_URL}/job_icons/wall_white.png`
          },
          {
              title:"ריצוף",
              description:["אבן בזלת","אבן מפירוק","אבן טבעית","שיש","קופינג","ספי חלון","בריקים","קרמיקה","פורצלן"],
              jobInfo: "עבודות ריצוף עם סוגי אבנים שונים ושיש.",
              gallery:[
                  `${process.env.PUBLIC_URL}/jobs_gallary/tilling_1.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/tilling_2.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/tilling_3.jpg`,
              ],
              icon:`${process.env.PUBLIC_URL}/job_icons/floor_white.png`
          },
      ]
    },
    {
      img: `${process.env.PUBLIC_URL}/cameras_images/surveillanceLogo.png`,
      title: "מערכות אבטחה",
      subTitle:"מכירה והתקנה של מערכות מיגון ואבטחה לבית ולעסק",
      description:"מערכות מיגון ואבטחה- אנו מתמחים בהתקנת מגוון מערכות מיגון ואבטחה בבתים פרטיים/ בניינים וכו׳, בנוסף בתיקון תקלות מכל הסוגים בכל המערכות השונות תוך זמינות ללקוח, אמינות ומקצועיות.",
      jobs:[
          {
              title:"מצלמות אבטחה",
              description:["מצלמות רשת (ip )","מצלמות אנלוגיות","מצלמות מתנייעות ptz","מצלמה סולארית","מצלמות wifi","מצלמות אנליטיקה","מצלמות זיהוי פנים","מצלמות lpr לזיהוי לוחית רכב"],
              jobInfo: "התקנה ותיקון של מצלמות אבטחה מסוגים שונים.",
              gallery:[
                  `${process.env.PUBLIC_URL}/jobs_gallary/cameras_1.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/cameras_2.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/cameras_3.jpg`,
              ],
              icon:`${process.env.PUBLIC_URL}/job_icons/camera_installation_white.png`
          },
          {
              title:"קודנים/אינטרקום",
              description:["התקנת ותיקון מגוון מערכות בקרת כניסה לפי דרישת הלקוח","מנעולים חשמליים","מנעולי אלקטרו מגנט"],
              jobInfo: "התקנה ותיקון של קודנים ומערכות אינטרקום.",
              gallery:[
                  `${process.env.PUBLIC_URL}/jobs_gallary/intercom_1.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/intercom_2.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/intercom_3.png`,
              ],
              icon:`${process.env.PUBLIC_URL}/job_icons/intercom_white.png`
          },
          {
              title:"מערכות שמע",
              description:["מגוון רחב של מערכות שמע וסאונד לבתים פרטיים, חנויות וכו׳"],
              jobInfo: "התקנת מערכות שמע וסאונד לבתים וחנויות.",
              gallery:[
                  `${process.env.PUBLIC_URL}/jobs_gallary/sound_systems_1.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/sound_systems_2.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/sound_systems_3.jpg`,
              ],
              icon:`${process.env.PUBLIC_URL}/job_icons/Audio_systems_white.png`
          },
          {
              title:"מערכות אזעקה",
              description:["מתן מענה ללקוח על פי צרכיו במגוון מערכות אזעקה המתקדמות בתחום"],
              jobInfo: "התקנה ותיקון של מערכות אזעקה מתקדמות.",
              gallery:[
                  `${process.env.PUBLIC_URL}/jobs_gallary/alarm_1.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/alarm_2.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/alarm_3.jpeg`,
              ],
              icon:`${process.env.PUBLIC_URL}/job_icons/alarm_system_white.png`
          },
          {
              title:"פריסת תשתיות",
              description:["השחלת והעברת תשתיות ממקום למקום בצורה יסודית ונקיה","תשתית חשמל","תשתית רשת","תשתית כריזה","תשתית אזעקה","הרחקות KVM","הרחקות HDMI"],
              jobInfo: "פריסת תשתיות חשמל, רשת, כריזה ואזעקה בצורה יסודית ונקיה.",
              gallery:[
                  `${process.env.PUBLIC_URL}/jobs_gallary/cabales_1.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/cabales_2.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/cabales_3.jpg`,
              ],
              icon:`${process.env.PUBLIC_URL}/job_icons/cables_installations_white.png`
          },
          {
              title:"התקנת ארונות תקשורת",
              description:["לפי דרישת הלקוח","מערכות אל פסק"],
              jobInfo: "התקנת ארונות תקשורת ומערכות אל פסק.",
              gallery:[
                  `${process.env.PUBLIC_URL}/jobs_gallary/cabinets_1.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/cabinets_2.jpg`,
                  `${process.env.PUBLIC_URL}/jobs_gallary/cabinets_3.jpg`,
              ],
              icon:`${process.env.PUBLIC_URL}/job_icons/electrical_cabinet_installation_white.png`
          },
      ]
    },
  ];
  